import styles from "./LoginPage.module.css";
import { useStore } from "../store";
import config from "../config";

import { useState } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function LoginPage() {
	const store = useStore();

	return (
		<div className={styles.page}>
			{config.login && !store.sessionId ? (
				<LoginForm />
			) : (
				<>
					<div className={styles.logo}>{config.site.name}</div>
					{config.site.loginSubtitle ? (
						<div className={styles.subtitle}>
							{config.site.loginSubtitle}
						</div>
					) : null}
					<PhoneSelectForm />
				</>
			)}
		</div>
	);
}

export default observer(LoginPage);

function LoginForm() {
	return (
		<div className={styles.login}>
			{config.login.title ? (
				<div className={styles.login__title}>{config.login.title}</div>
			) : null}
			{config.login.type === "token" ? <TokenLoginForm /> : null}
		</div>
	);
}

const TokenLoginForm = observer(function TokenLoginForm() {
	const store = useStore();
	const { loggingIn } = store;
	const [token, setToken] = useState("");
	const [error, setError] = useState("");

	function handleSubmit(evt) {
		evt.preventDefault();
		if (token) {
			store.login({ token }).catch((error) => setError(error));
		}
	}

	const disabled = !token;

	return (
		<form className={styles.form} onSubmit={handleSubmit}>
			<input
				type="text"
				className={styles.form__input}
				placeholder={config.login.placeholder || "Token"}
				value={token}
				onChange={(evt) => setToken(evt.target.value)}
			/>
			<button
				className={classnames(
					styles.form__submit,
					disabled ? styles.disabled : null,
					loggingIn ? styles.loading : null
				)}
			>
				{loggingIn ? "Loading..." : config.login.submitText || "Enter"}
			</button>
			<div className={styles.form__error}>{error}</div>
		</form>
	);
});

const PhoneSelectForm = observer(function PhoneSelectForm() {
	const store = useStore();
	const { connectionError } = store;
	const [phoneId, setPhoneId] = useState("");

	function handleSubmit(evt) {
		evt.preventDefault();
		if (phoneId) {
			store.connectToPhone(phoneId.toLowerCase());
		}
	}

	const disabled = !phoneId;

	return (
		<form className={styles.form} onSubmit={handleSubmit}>
			<input
				type="text"
				className={styles.form__input}
				placeholder="Phone number"
				value={phoneId}
				onChange={(evt) => setPhoneId(evt.target.value)}
			/>
			<button
				className={classnames(
					styles.form__submit,
					disabled ? styles.disabled : null
				)}
			>
				{config.site.loginButtonText}
			</button>
			<div className={styles.form__error}>
				{connectionError ? config.site.invalidPhoneMessage : null}
			</div>
		</form>
	);
});
