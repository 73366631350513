import styles from "./App.module.css";
import LoginPage from "./LoginPage";
import ConnectingPage from "./ConnectingPage";
import { useStore } from "../store";
import config from "../config.json";

import { PhoneSimulator } from "@faintlines/phone-simulator";
import "@faintlines/phone-simulator/dist/index.css";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import { observer } from "mobx-react-lite";
import classnames from "classnames";

export default function App() {
    return (
        <div className={classnames(styles.app, config.site.theme)}>
            <SiteContent />
            {config.widget ? (
                <Widget config={config.widget} storyConfig={config.story} />
            ) : null}
        </div>
    );
}

const SiteContent = observer(() => {
    const store = useStore();
    const { activePhoneId, activePhone, connecting } = store;

    if (!activePhoneId || !activePhone) {
        if (connecting) {
            return <ConnectingPage />;
        }
        return <LoginPage />;
    }

    return (
        <div className={styles.phoneWrapper}>
            <div className={styles.phoneWrapperContents}>
                <PhoneSimulator
                    story={activePhone}
                    config={{ persistenceKeyPrefix: "phones" }}
                    onExit={() => store.exitPhone()}
                />
            </div>
        </div>
    );
});
