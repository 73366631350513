import styles from "./ConnectingPage.module.css";
import config from "../config";

export default function ConnectingPage() {
	return (
		<div className={styles.page}>
			<div className={styles.title}>{config.site.connectingText}</div>
		</div>
	);
}
