import "./index.css";
import config from "./config";
import App from "./components/App";
import { Store, StoreContext } from "./store";

import * as Backend from "@faintlines/backend-client";

import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "@fontsource/ubuntu-mono/400.css";
import "@fontsource/ubuntu-mono/700.css";

Backend.configure({
	storyId: config.story.id,
	assetId: config.story.assetId,
});

const store = new Store();

ReactDOM.render(
	<React.StrictMode>
		<StoreContext.Provider value={store}>
			<HelmetProvider>
				<Helmet>
					<title>{config.site.name}</title>
					{config.site.description ? (
						<meta
							name="description"
							content={config.site.description}
						/>
					) : null}
					{config.site.favicon ? (
						<link rel="icon" href={config.site.favicon} />
					) : null}
				</Helmet>
				<App />
			</HelmetProvider>
		</StoreContext.Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
